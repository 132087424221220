import axios from "axios";
import { APIResponse } from "~/types";
import { PlanInfo } from "~/types/ba-payment";

export const getAccessToken = (): any => {
  const token = localStorage.getItem("auth._token.bamidp") || "";
  const idToken = token !== "" ? token.split(" ")[1] : "";
  return axios({
    method: "GET",
    url: process.env.IDP_BASE_URL + "/oidc/token/use_idtoken",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token
    },
    params: { id_token: idToken }
  });
};

export const getUserInfo = (bankCode: string, accessToken: string): any => {
  return axios({
    method: "POST",
    url: `${process.env.IDP_BASE_URL}/oidc/bank${bankCode}/userinfo`,
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + accessToken
    }
  });
};

export const getBaConfig = (): any => {
  const token = localStorage.getItem("auth._token.bamidp") || "";
  const xba_url = localStorage.getItem("xba_url");

  return axios({
    method: "GET",
    url: xba_url + "/api/v1/app/config",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: token
    }
  });
};

export const getBaCompany = (): any => {
  const token = localStorage.getItem("auth._token.bamidp") || "";
  const xba_url = localStorage.getItem("xba_url");

  return axios({
    method: "GET",
    url: xba_url + "/api/v1/company/company",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: token
    }
  });
};

export const getBaPaymentMe = (headerConfig: object = {}): any => {
  const token = localStorage.getItem("auth._token.bamidp") || "";

  return axios({
    method: "GET",
    url: process.env.API_URL + "/api/v1/user_service/me",
    headers: {
      ...headerConfig,
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: token
    }
  });
};

export const getBaPaymentInfo = (): any => {
  const token = localStorage.getItem("auth._token.bamidp") || "";

  return axios({
    method: "GET",
    url: process.env.API_URL + "/api/v1/payment_company/list",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: token
    }
  });
};

/**
 * プラン情報取得API
 * @returns
 */
export const getBaPlanInfo = () => {
  const token = localStorage.getItem("auth._token.bamidp") || "";

  return axios.get<APIResponse<PlanInfo>>(process.env.API_URL + "/api/v1/user_service/planinfo", {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: token
    }
  });
};

/**
 * BAユーザ取得
 * @returns
 */
export const getBaUserMe = () => {
  const token = localStorage.getItem("auth._token.bamidp") || "";
  const xbaUrl = localStorage.getItem("xba_url");

  return axios({
    method: "GET",
    url: `${xbaUrl}/api/v1/user/me`,
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: token
    }
  });
};
