import { NuxtAppOptions } from "@nuxt/types";
import Ba from "~/store/ba";
import { baStore, isSystemAdminZba } from "~/utils/store-accessor";

export default async ({ route, $auth, $sentry, redirect, error, store }: NuxtAppOptions): Promise<void> => {
  /**
   * エラー発生時、Sentryにイベントを時の共通データをセットする
   *
   * 仮にロギング処理内でエラーが発生しても、無視して通常処理を続行させるようにしています。
   */
  const setDimensionToSentry = (baStore: Ba) => {
    try {
      $sentry.setUser({
        ba_user_bank_code: baStore.user.ba_user_bank_code,
        ba_user_bank_name: baStore.user.ba_user_bank_name,
        ba_user_type: baStore.user.ba_user_type,
        ba_user_unique_code: baStore.user.ba_user_unique_code,
        user_kind: "admin"
      });
    } catch {}
  };

  if (!$auth.loggedIn) {
    // bamidpにログインしていない場合はエラー
    error({ statusCode: 401 });
  }

  store!.commit("notice/clearState");

  // アクセストークンでユーザ情報（銀行名など）取得
  await baStore.loadUser();

  if (!baStore.user || !baStore.user.is_credit_card) {
    // ユーザ情報が取得できていないまたはBA側でクレジットカード登録されていない場合
    error({ statusCode: 401 });
  }

  // エラー発生時にSentryへ常に送信する基本情報をセット
  setDimensionToSentry(baStore);

  if (baStore.user.ba_user_type === "company_owner" || baStore.user.ba_user_type === "company_user") {
    // 企業側アカウントのときは企業側画面のトップにリダイレクト
    redirect("/top");
  }

  // 金融機関設定はZBAのシステム管理者のみアクセス可能
  if (route.path.includes("bank-setting") && !isSystemAdminZba()) {
    error({ statusCode: 403 });
  }

  if (
    baStore.user.ba_user_type !== "system_admin" &&
    baStore.user.ba_user_type !== "bank_admin" &&
    baStore.user.ba_user_type !== "branch_admin" &&
    baStore.user.ba_user_type !== "branch_user"
  ) {
    // システム管理者、金融機関（事務局）、金融機関（支店）以外ならエラー
    error({ statusCode: 401 });
  }
};
