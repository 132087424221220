import VuexPersistence from 'vuex-persist';

// eslint-disable-next-line
export default ({ store }) => {
  new VuexPersistence({
    storage: window.localStorage,
    // eslint-disable-next-line
    reducer: (state) => ({ constants: state.constants }),
  }).plugin(store);
};
