export type PathKey =
  // 利用企業一覧
  "adminCompanies" | "adminBanks";
// ExamplePath1
// | 'examplePath1'
// ExamplePath1
// | 'examplePath2'

export const getPath = (key: PathKey) => paths[key];

const paths: Record<PathKey, string> = {
  adminCompanies: "/admin/companies/list",
  adminBanks: "/admin/bank-setting/list"
  // examplePath1: '/admin/example1',
  // examplePath2: '/admin/example2',
};
