
import { computed, defineComponent } from "vue";
import { useLoading } from "@/store";

export default defineComponent({
  setup() {
    /**
     * Computed
     */
    const isShow = computed(() => {
      return useLoading.isShow;
    });

    /**
     * Methods
     */
    const start = (): void => {
      useLoading.showLoading();
    };
    const finish = (): void => {
      useLoading.hideLoading();
    };

    return {
      isShow,
      start,
      finish
    };
  }
});
