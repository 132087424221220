// eslint-disable-next-line @typescript-eslint/no-empty-function
import { baPaymentStore, baStore } from "~/utils/store-accessor";
import { isUseValid } from "~/utils";
import { IBaPaymentUser } from "~/types/ba-payment";
import { BA_USER_TYPE } from "~/utils/constants";
import { adminCompaniesListLink, registerLink } from "~/utils/routes";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default function ({ redirect, error }) {
  /** ミドルウェアで使用する関数の定義 **/
  /**
   * 有効なユーザーが存在するかどうかを確認する
   * @param user
   */
  const isExistPaymentUser = (user: IBaPaymentUser) => {
    return !!user.company_unique_code;
  };

  /**
   * 企業オーナーかどうかを判定する
   * @param userType
   */
  const isOwner = (userType: string) => {
    return userType === BA_USER_TYPE.COMPANY_OWNER;
  };

  /**
   * ビジネスユーザーかどうかを判定する
   * @param userType
   */
  const isBusinessUser = (userType: string) => {
    return userType === BA_USER_TYPE.COMPANY_USER;
  };

  /**
   * 管理ユーザーかどうかを判定する
   * @param userType
   */
  const isAdminUser = (userType: string) => {
    const adminUserTypes = [
      BA_USER_TYPE.SYSTEM_ADMIN, // システム管理者
      BA_USER_TYPE.BANK_ADMIN, // 金融機関（事務局）
      BA_USER_TYPE.BRANCH_ADMIN, // 金融機関（支店）
      BA_USER_TYPE.BRANCH_USER // 金融機関（支店）ユーザー
    ];
    return adminUserTypes.includes(userType);
  };

  /** ミドルウェアの処理開始 **/
  // システム管理者、金融機関（事務局）、金融機関（支店）の場合は管理画面TOPにリダイレクト
  if (isAdminUser(baStore.user.ba_user_type)) {
    redirect(adminCompaniesListLink);
    return;
  }

  // 有効なユーザーが存在しない、または、存在するが利用期間外の場合
  if (
    !isExistPaymentUser(baPaymentStore.user) ||
    !isUseValid(baPaymentStore.user.use_start_date, baPaymentStore.user.use_end_date)
  ) {
    // 企業オーナーなら新規登録画面へ
    if (isOwner(baStore.user.ba_user_type)) {
      redirect(registerLink);
      return;
    }
    // ビジネスユーザーの場合はエラー
    if (isBusinessUser(baStore.user.ba_user_type)) {
      error({ statusCode: 401 });
      console.error("利用登録されていないビジネスユーザのため、ご利用いただけません。");
      return; // eslint-disable-line
    }
  }
}
