import { Context, Plugin } from '@nuxt/types';

export interface UserAgentInterface {
  isIphone(): boolean,
  isIpad(): boolean,
  isAndroid(): boolean,
}

class UserAgent implements UserAgentInterface {
  private readonly _ua: string;
  constructor (userAgent: string) {
    this._ua = userAgent;
  }

  isIphone(): boolean {
    return this._ua.includes('iphone');
  }

  isIpad(): boolean {
    // iOS13以降のiPadのSafariで「デスクトップ用Webサイトを表示」
    // を使用するとipadを含まない
    return this._ua.includes('ipad') ||
      (this._ua.includes('macintosh') && 'ontouchend' in document);
  }

  isAndroid(): boolean {
    // タブレットとモバイルどちらも含む
    return this._ua.includes('android');
  }
}

const userAgent: Plugin = (ctx: Context, inject) => {
  let userAgent = process.server
    ? ctx.req.headers['user-agent']
    : navigator.userAgent;
  userAgent = userAgent
    ? userAgent.toLowerCase()
    : '';

  inject('ua', new UserAgent(userAgent));
};

export default userAgent;
