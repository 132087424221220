import Vue from "vue";

export default () => {
  /**
   * エラーハンドラーの登録
   *
   * Nuxt内のデフォルトのエラーハンドラを実行させないためハンドラを上書きしています。
   * Nuxt内のデフォルトの処理は、context.error(e)を実行することです。
   * 上書きしをない場合、axiosのonError()とNuxt内のcontext.error(e)が２重で走り、
   * AxiosのonError()で呼び出したcontext.error()のステータスコードは上書きされてしまいます。
   *
   * @returns
   */
  Vue.config.errorHandler = () => {
    return true;
  };
};
