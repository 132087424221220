import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";

export type State = {
  isShow: boolean;
};

@Module({ stateFactory: true, namespaced: true, name: "use-loading" })
export default class UseLoading extends VuexModule implements State {
  isShow = false;

  @Mutation
  setIsShow(isShow: boolean) {
    this.isShow = isShow;
  }

  @Action({ rawError: true })
  showLoading() {
    this.setIsShow(true);
  }

  @Action({ rawError: true })
  hideLoading() {
    this.setIsShow(false);
  }
}
