/**
 * アプリケーション全体で使用する通知メッセージ関連のstoreです。
 */
import { ActionContext } from "vuex";

export type NoticeState = {
  type: "success" | "error";
  show: boolean;
  message: string;
};

export const state = (): NoticeState => ({
  type: "error",
  show: false,
  message: ""
});

type ActionCtx = ActionContext<NoticeState, NoticeState>;

export const mutations = {
  setShow(state: NoticeState, show: boolean) {
    state.show = show;
  },
  setState(state: NoticeState, newState: NoticeState) {
    state.type = newState.type;
    state.show = newState.show;
    state.message = newState.message;
  },
  clearState(state: NoticeState) {
    state.type = "error";
    state.show = false;
    state.message = "";
  }
};

export const actions = {
  /**
   * メッセージ（帯）を表示します。
   *
   * @param param
   * @param newState
   */
  show({ commit }: ActionCtx, newState: NoticeState) {
    // Note:
    // Vuexの型定義がデフォルトanyへの対応を行っています。
    // ・ NoticeStateで定義したプロパティが存在しない場合、デフォルト値をセットします。
    // ・ NoticeStateで定義しているプロパティのみ更新します。
    // Vuexの型定義ライブラリや自作でもType定義は可能なので、もし対応したら以下のオブジェクトの生成は削除して大丈夫です。
    const updateState: NoticeState = {
      type: newState?.type ?? "error",
      show: newState?.show ?? true,
      message: newState?.message ?? ""
    };
    commit("setState", updateState);
  }
};
