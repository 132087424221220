// injectとprovideによる状態共有を行う
import { provide, inject, reactive, Ref, toRefs, ref } from "@nuxtjs/composition-api";
import { ServicePlanEx } from "~/types/master";
import { PLAN_ID } from "~/utils/constants";

export const key = Symbol("key");

let create: any = null;

interface State {
  isMobile: boolean;
  mobileWidth: number;
  device: string;
  registerPlanId: number | null;
  registerAgree: boolean;
  registerAgree2: boolean;
  servicePlans: Ref<ServicePlanEx[]>;
}

export type AccountsState = State;

export const createStore = (): any => {
  const state = reactive<State>({
    isMobile: false,
    mobileWidth: 1025,
    device: "pc",
    registerPlanId: null,
    registerAgree: false,
    registerAgree2: false,
    servicePlans: ref([])
  });

  const onResize = (): void => {
    state.isMobile = window.innerWidth < state.mobileWidth;
    if (window.innerWidth >= state.mobileWidth) {
      state.device = "pc";
    } else if (window.innerWidth > 480 && window.innerWidth < state.mobileWidth) {
      state.device = "tablet";
    } else {
      state.device = "sp";
    }
  };

  return {
    onResize,
    ...toRefs(state)
  };
};

/**
 * 申込時の選択情報をリセットします
 */
export const resetAppSelections = () => {
  create.registerPlanId.value = PLAN_ID.NONE;
  create.registerAgree.value = false;
  create.registerAgree2.value = false;
};

export const provideStore = (): any => {
  create = createStore();
  provide(key, create);
};

export const getStore = (): any => {
  return create;
};

export const useStore = (): any => {
  return inject(key);
};
