import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _442485af = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _73e24fc1 = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _0a198232 = () => interopDefault(import('../pages/healthcheck.vue' /* webpackChunkName: "pages/healthcheck" */))
const _4e16ad99 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _666d50c0 = () => interopDefault(import('../pages/office-process-rule-templates/index.vue' /* webpackChunkName: "pages/office-process-rule-templates/index" */))
const _27de4668 = () => interopDefault(import('../pages/receipts/index.vue' /* webpackChunkName: "pages/receipts/index" */))
const _d1584e4a = () => interopDefault(import('../pages/top.vue' /* webpackChunkName: "pages/top" */))
const _7094be98 = () => interopDefault(import('../pages/view-pdf.vue' /* webpackChunkName: "pages/view-pdf" */))
const _e6ef2996 = () => interopDefault(import('../pages/accounts/register/index.vue' /* webpackChunkName: "pages/accounts/register/index" */))
const _4707ce96 = () => interopDefault(import('../pages/accounts/settings/index.vue' /* webpackChunkName: "pages/accounts/settings/index" */))
const _13ac71ab = () => interopDefault(import('../pages/bamidp/login.vue' /* webpackChunkName: "pages/bamidp/login" */))
const _6644299a = () => interopDefault(import('../pages/companies/create.vue' /* webpackChunkName: "pages/companies/create" */))
const _a5343c88 = () => interopDefault(import('../pages/companies/list.vue' /* webpackChunkName: "pages/companies/list" */))
const _b0b7c240 = () => interopDefault(import('../pages/customers/create.vue' /* webpackChunkName: "pages/customers/create" */))
const _1eae7082 = () => interopDefault(import('../pages/customers/list.vue' /* webpackChunkName: "pages/customers/list" */))
const _5dd7c297 = () => interopDefault(import('../pages/delivery-slips/create.vue' /* webpackChunkName: "pages/delivery-slips/create" */))
const _215dbd65 = () => interopDefault(import('../pages/delivery-slips/edit.vue' /* webpackChunkName: "pages/delivery-slips/edit" */))
const _121595f9 = () => interopDefault(import('../pages/delivery-slips/list.vue' /* webpackChunkName: "pages/delivery-slips/list" */))
const _4ec3252a = () => interopDefault(import('../pages/estimates/create.vue' /* webpackChunkName: "pages/estimates/create" */))
const _0cd6a8b8 = () => interopDefault(import('../pages/estimates/edit.vue' /* webpackChunkName: "pages/estimates/edit" */))
const _04e2fd68 = () => interopDefault(import('../pages/estimates/list.vue' /* webpackChunkName: "pages/estimates/list" */))
const _041fdacb = () => interopDefault(import('../pages/invoices/create.vue' /* webpackChunkName: "pages/invoices/create" */))
const _29135ace = () => interopDefault(import('../pages/invoices/edit.vue' /* webpackChunkName: "pages/invoices/edit" */))
const _47a3a9a6 = () => interopDefault(import('../pages/invoices/list.vue' /* webpackChunkName: "pages/invoices/list" */))
const _21326677 = () => interopDefault(import('../pages/items/list.vue' /* webpackChunkName: "pages/items/list" */))
const _c10c0002 = () => interopDefault(import('../pages/legal/kokopelli-id/index.vue' /* webpackChunkName: "pages/legal/kokopelli-id/index" */))
const _08c25d54 = () => interopDefault(import('../pages/receipts/create.vue' /* webpackChunkName: "pages/receipts/create" */))
const _45b5c202 = () => interopDefault(import('../pages/receive/invoices/index.vue' /* webpackChunkName: "pages/receive/invoices/index" */))
const _0efcaebc = () => interopDefault(import('../pages/receive/others/index.vue' /* webpackChunkName: "pages/receive/others/index" */))
const _3ff7ab14 = () => interopDefault(import('../pages/receive/receipts/index.vue' /* webpackChunkName: "pages/receive/receipts/index" */))
const _62eeded8 = () => interopDefault(import('../pages/reports/list.vue' /* webpackChunkName: "pages/reports/list" */))
const _5af95539 = () => interopDefault(import('../pages/settings/documents/index.vue' /* webpackChunkName: "pages/settings/documents/index" */))
const _af0fec48 = () => interopDefault(import('../pages/support/faq.vue' /* webpackChunkName: "pages/support/faq" */))
const _30dd53ba = () => interopDefault(import('../pages/support/qa.vue' /* webpackChunkName: "pages/support/qa" */))
const _02d18bea = () => interopDefault(import('../pages/terms/chanto-term.vue' /* webpackChunkName: "pages/terms/chanto-term" */))
const _93b751ca = () => interopDefault(import('../pages/terms/kokopelli-id-term.vue' /* webpackChunkName: "pages/terms/kokopelli-id-term" */))
const _62d8c674 = () => interopDefault(import('../pages/accounts/change/permissions.vue' /* webpackChunkName: "pages/accounts/change/permissions" */))
const _6870ebb0 = () => interopDefault(import('../pages/accounts/register/addplan/index.vue' /* webpackChunkName: "pages/accounts/register/addplan/index" */))
const _19450d09 = () => interopDefault(import('../pages/accounts/register/changeplan/index.vue' /* webpackChunkName: "pages/accounts/register/changeplan/index" */))
const _e0fb093a = () => interopDefault(import('../pages/accounts/register/confirm.vue' /* webpackChunkName: "pages/accounts/register/confirm" */))
const _01a67b3a = () => interopDefault(import('../pages/accounts/user/list.vue' /* webpackChunkName: "pages/accounts/user/list" */))
const _1b0f7634 = () => interopDefault(import('../pages/admin/bank-setting/list.vue' /* webpackChunkName: "pages/admin/bank-setting/list" */))
const _51a91a08 = () => interopDefault(import('../pages/admin/companies/list.vue' /* webpackChunkName: "pages/admin/companies/list" */))
const _2199e8c2 = () => interopDefault(import('../pages/receive/invoices/create.vue' /* webpackChunkName: "pages/receive/invoices/create" */))
const _0f6518fc = () => interopDefault(import('../pages/receive/others/create.vue' /* webpackChunkName: "pages/receive/others/create" */))
const _329bfbac = () => interopDefault(import('../pages/receive/receipts/create.vue' /* webpackChunkName: "pages/receive/receipts/create" */))
const _134cc91e = () => interopDefault(import('../pages/accounts/register/addplan/confirm.vue' /* webpackChunkName: "pages/accounts/register/addplan/confirm" */))
const _40c40f92 = () => interopDefault(import('../pages/accounts/register/changeplan/confirm.vue' /* webpackChunkName: "pages/accounts/register/changeplan/confirm" */))
const _022a8b9a = () => interopDefault(import('../pages/accounts/settings/unregister/confirm.vue' /* webpackChunkName: "pages/accounts/settings/unregister/confirm" */))
const _f2ff4084 = () => interopDefault(import('../pages/accounts/settings/unregister/reserved.vue' /* webpackChunkName: "pages/accounts/settings/unregister/reserved" */))
const _60c3478d = () => interopDefault(import('../pages/accounts/user/detail/_user_unique_code.vue' /* webpackChunkName: "pages/accounts/user/detail/_user_unique_code" */))
const _454ae2a8 = () => interopDefault(import('../pages/admin/bank-setting/detail/_bank_code/index.vue' /* webpackChunkName: "pages/admin/bank-setting/detail/_bank_code/index" */))
const _2f6c4949 = () => interopDefault(import('../pages/delivery-slips/mail/detail/_id.vue' /* webpackChunkName: "pages/delivery-slips/mail/detail/_id" */))
const _7a5b1c16 = () => interopDefault(import('../pages/estimates/mail/detail/_id.vue' /* webpackChunkName: "pages/estimates/mail/detail/_id" */))
const _c8aba8d6 = () => interopDefault(import('../pages/invoices/mail/detail/_id.vue' /* webpackChunkName: "pages/invoices/mail/detail/_id" */))
const _6880616a = () => interopDefault(import('../pages/receipts/mail/detail/_id.vue' /* webpackChunkName: "pages/receipts/mail/detail/_id" */))
const _1b8cc41f = () => interopDefault(import('../pages/receive/invoices/detail/_id.vue' /* webpackChunkName: "pages/receive/invoices/detail/_id" */))
const _5b9810d0 = () => interopDefault(import('../pages/receive/invoices/edit/_id.vue' /* webpackChunkName: "pages/receive/invoices/edit/_id" */))
const _094a67d2 = () => interopDefault(import('../pages/receive/invoices/snapshot/_id.vue' /* webpackChunkName: "pages/receive/invoices/snapshot/_id" */))
const _80cafafc = () => interopDefault(import('../pages/receive/others/detail/_id.vue' /* webpackChunkName: "pages/receive/others/detail/_id" */))
const _03581a8a = () => interopDefault(import('../pages/receive/others/edit/_id.vue' /* webpackChunkName: "pages/receive/others/edit/_id" */))
const _3e3bcd16 = () => interopDefault(import('../pages/receive/others/snapshot/_id.vue' /* webpackChunkName: "pages/receive/others/snapshot/_id" */))
const _937105ac = () => interopDefault(import('../pages/receive/receipts/detail/_id.vue' /* webpackChunkName: "pages/receive/receipts/detail/_id" */))
const _3461113a = () => interopDefault(import('../pages/receive/receipts/edit/_id.vue' /* webpackChunkName: "pages/receive/receipts/edit/_id" */))
const _3f89ebc6 = () => interopDefault(import('../pages/receive/receipts/snapshot/_id.vue' /* webpackChunkName: "pages/receive/receipts/snapshot/_id" */))
const _cb4f1096 = () => interopDefault(import('../pages/admin/bank-setting/detail/_bank_code/free-trial-reservation/_id.vue' /* webpackChunkName: "pages/admin/bank-setting/detail/_bank_code/free-trial-reservation/_id" */))
const _461b0bd9 = () => interopDefault(import('../pages/admin/companies/detail/_company_unique_code/_join_date.vue' /* webpackChunkName: "pages/admin/companies/detail/_company_unique_code/_join_date" */))
const _32ad3e99 = () => interopDefault(import('../pages/customers/edit/_id.vue' /* webpackChunkName: "pages/customers/edit/_id" */))
const _aae581d2 = () => interopDefault(import('../pages/delivery-slips/detail/_id.vue' /* webpackChunkName: "pages/delivery-slips/detail/_id" */))
const _4bcff66c = () => interopDefault(import('../pages/delivery-slips/snapshot/_id.vue' /* webpackChunkName: "pages/delivery-slips/snapshot/_id" */))
const _c9e1bfac = () => interopDefault(import('../pages/estimates/detail/_id.vue' /* webpackChunkName: "pages/estimates/detail/_id" */))
const _9cb425c6 = () => interopDefault(import('../pages/estimates/snapshot/_id.vue' /* webpackChunkName: "pages/estimates/snapshot/_id" */))
const _41e61d4b = () => interopDefault(import('../pages/invoices/detail/_id.vue' /* webpackChunkName: "pages/invoices/detail/_id" */))
const _029bb404 = () => interopDefault(import('../pages/invoices/snapshot/_id.vue' /* webpackChunkName: "pages/invoices/snapshot/_id" */))
const _cb4920ce = () => interopDefault(import('../pages/legal/terms/_bank_code.vue' /* webpackChunkName: "pages/legal/terms/_bank_code" */))
const _46be5354 = () => interopDefault(import('../pages/receipts/detail/_id.vue' /* webpackChunkName: "pages/receipts/detail/_id" */))
const _72c52b8f = () => interopDefault(import('../pages/receipts/edit/_id.vue' /* webpackChunkName: "pages/receipts/edit/_id" */))
const _54ba6f6e = () => interopDefault(import('../pages/receipts/snapshot/_id.vue' /* webpackChunkName: "pages/receipts/snapshot/_id" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/callback",
    component: _442485af,
    name: "callback"
  }, {
    path: "/cookie-policy",
    component: _73e24fc1,
    name: "cookie-policy"
  }, {
    path: "/healthcheck",
    component: _0a198232,
    name: "healthcheck"
  }, {
    path: "/maintenance",
    component: _4e16ad99,
    name: "maintenance"
  }, {
    path: "/office-process-rule-templates",
    component: _666d50c0,
    name: "office-process-rule-templates"
  }, {
    path: "/receipts",
    component: _27de4668,
    name: "receipts"
  }, {
    path: "/top",
    component: _d1584e4a,
    name: "top"
  }, {
    path: "/view-pdf",
    component: _7094be98,
    name: "view-pdf"
  }, {
    path: "/accounts/register",
    component: _e6ef2996,
    name: "accounts-register"
  }, {
    path: "/accounts/settings",
    component: _4707ce96,
    name: "accounts-settings"
  }, {
    path: "/bamidp/login",
    component: _13ac71ab,
    name: "bamidp-login"
  }, {
    path: "/companies/create",
    component: _6644299a,
    name: "companies-create"
  }, {
    path: "/companies/list",
    component: _a5343c88,
    name: "companies-list"
  }, {
    path: "/customers/create",
    component: _b0b7c240,
    name: "customers-create"
  }, {
    path: "/customers/list",
    component: _1eae7082,
    name: "customers-list"
  }, {
    path: "/delivery-slips/create",
    component: _5dd7c297,
    name: "delivery-slips-create"
  }, {
    path: "/delivery-slips/edit",
    component: _215dbd65,
    name: "delivery-slips-edit"
  }, {
    path: "/delivery-slips/list",
    component: _121595f9,
    name: "delivery-slips-list"
  }, {
    path: "/estimates/create",
    component: _4ec3252a,
    name: "estimates-create"
  }, {
    path: "/estimates/edit",
    component: _0cd6a8b8,
    name: "estimates-edit"
  }, {
    path: "/estimates/list",
    component: _04e2fd68,
    name: "estimates-list"
  }, {
    path: "/invoices/create",
    component: _041fdacb,
    name: "invoices-create"
  }, {
    path: "/invoices/edit",
    component: _29135ace,
    name: "invoices-edit"
  }, {
    path: "/invoices/list",
    component: _47a3a9a6,
    name: "invoices-list"
  }, {
    path: "/items/list",
    component: _21326677,
    name: "items-list"
  }, {
    path: "/legal/kokopelli-id",
    component: _c10c0002,
    name: "legal-kokopelli-id"
  }, {
    path: "/receipts/create",
    component: _08c25d54,
    name: "receipts-create"
  }, {
    path: "/receive/invoices",
    component: _45b5c202,
    name: "receive-invoices"
  }, {
    path: "/receive/others",
    component: _0efcaebc,
    name: "receive-others"
  }, {
    path: "/receive/receipts",
    component: _3ff7ab14,
    name: "receive-receipts"
  }, {
    path: "/reports/list",
    component: _62eeded8,
    name: "reports-list"
  }, {
    path: "/settings/documents",
    component: _5af95539,
    name: "settings-documents"
  }, {
    path: "/support/faq",
    component: _af0fec48,
    name: "support-faq"
  }, {
    path: "/support/qa",
    component: _30dd53ba,
    name: "support-qa"
  }, {
    path: "/terms/chanto-term",
    component: _02d18bea,
    name: "terms-chanto-term"
  }, {
    path: "/terms/kokopelli-id-term",
    component: _93b751ca,
    name: "terms-kokopelli-id-term"
  }, {
    path: "/accounts/change/permissions",
    component: _62d8c674,
    name: "accounts-change-permissions"
  }, {
    path: "/accounts/register/addplan",
    component: _6870ebb0,
    name: "accounts-register-addplan"
  }, {
    path: "/accounts/register/changeplan",
    component: _19450d09,
    name: "accounts-register-changeplan"
  }, {
    path: "/accounts/register/confirm",
    component: _e0fb093a,
    name: "accounts-register-confirm"
  }, {
    path: "/accounts/user/list",
    component: _01a67b3a,
    name: "accounts-user-list"
  }, {
    path: "/admin/bank-setting/list",
    component: _1b0f7634,
    name: "admin-bank-setting-list"
  }, {
    path: "/admin/companies/list",
    component: _51a91a08,
    name: "admin-companies-list"
  }, {
    path: "/receive/invoices/create",
    component: _2199e8c2,
    name: "receive-invoices-create"
  }, {
    path: "/receive/others/create",
    component: _0f6518fc,
    name: "receive-others-create"
  }, {
    path: "/receive/receipts/create",
    component: _329bfbac,
    name: "receive-receipts-create"
  }, {
    path: "/accounts/register/addplan/confirm",
    component: _134cc91e,
    name: "accounts-register-addplan-confirm"
  }, {
    path: "/accounts/register/changeplan/confirm",
    component: _40c40f92,
    name: "accounts-register-changeplan-confirm"
  }, {
    path: "/accounts/settings/unregister/confirm",
    component: _022a8b9a,
    name: "accounts-settings-unregister-confirm"
  }, {
    path: "/accounts/settings/unregister/reserved",
    component: _f2ff4084,
    name: "accounts-settings-unregister-reserved"
  }, {
    path: "/accounts/user/detail/:user_unique_code?",
    component: _60c3478d,
    name: "accounts-user-detail-user_unique_code"
  }, {
    path: "/admin/bank-setting/detail/:bank_code",
    component: _454ae2a8,
    name: "admin-bank-setting-detail-bank_code"
  }, {
    path: "/delivery-slips/mail/detail/:id?",
    component: _2f6c4949,
    name: "delivery-slips-mail-detail-id"
  }, {
    path: "/estimates/mail/detail/:id?",
    component: _7a5b1c16,
    name: "estimates-mail-detail-id"
  }, {
    path: "/invoices/mail/detail/:id?",
    component: _c8aba8d6,
    name: "invoices-mail-detail-id"
  }, {
    path: "/receipts/mail/detail/:id?",
    component: _6880616a,
    name: "receipts-mail-detail-id"
  }, {
    path: "/receive/invoices/detail/:id?",
    component: _1b8cc41f,
    name: "receive-invoices-detail-id"
  }, {
    path: "/receive/invoices/edit/:id?",
    component: _5b9810d0,
    name: "receive-invoices-edit-id"
  }, {
    path: "/receive/invoices/snapshot/:id?",
    component: _094a67d2,
    name: "receive-invoices-snapshot-id"
  }, {
    path: "/receive/others/detail/:id?",
    component: _80cafafc,
    name: "receive-others-detail-id"
  }, {
    path: "/receive/others/edit/:id?",
    component: _03581a8a,
    name: "receive-others-edit-id"
  }, {
    path: "/receive/others/snapshot/:id?",
    component: _3e3bcd16,
    name: "receive-others-snapshot-id"
  }, {
    path: "/receive/receipts/detail/:id?",
    component: _937105ac,
    name: "receive-receipts-detail-id"
  }, {
    path: "/receive/receipts/edit/:id?",
    component: _3461113a,
    name: "receive-receipts-edit-id"
  }, {
    path: "/receive/receipts/snapshot/:id?",
    component: _3f89ebc6,
    name: "receive-receipts-snapshot-id"
  }, {
    path: "/admin/bank-setting/detail/:bank_code?/free-trial-reservation/:id",
    component: _cb4f1096,
    name: "admin-bank-setting-detail-bank_code-free-trial-reservation-id"
  }, {
    path: "/admin/companies/detail/:company_unique_code?/:join_date?",
    component: _461b0bd9,
    name: "admin-companies-detail-company_unique_code-join_date"
  }, {
    path: "/customers/edit/:id?",
    component: _32ad3e99,
    name: "customers-edit-id"
  }, {
    path: "/delivery-slips/detail/:id?",
    component: _aae581d2,
    name: "delivery-slips-detail-id"
  }, {
    path: "/delivery-slips/snapshot/:id?",
    component: _4bcff66c,
    name: "delivery-slips-snapshot-id"
  }, {
    path: "/estimates/detail/:id?",
    component: _c9e1bfac,
    name: "estimates-detail-id"
  }, {
    path: "/estimates/snapshot/:id?",
    component: _9cb425c6,
    name: "estimates-snapshot-id"
  }, {
    path: "/invoices/detail/:id?",
    component: _41e61d4b,
    name: "invoices-detail-id"
  }, {
    path: "/invoices/snapshot/:id?",
    component: _029bb404,
    name: "invoices-snapshot-id"
  }, {
    path: "/legal/terms/:bank_code?",
    component: _cb4920ce,
    name: "legal-terms-bank_code"
  }, {
    path: "/receipts/detail/:id?",
    component: _46be5354,
    name: "receipts-detail-id"
  }, {
    path: "/receipts/edit/:id?",
    component: _72c52b8f,
    name: "receipts-edit-id"
  }, {
    path: "/receipts/snapshot/:id?",
    component: _54ba6f6e,
    name: "receipts-snapshot-id"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
