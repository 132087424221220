
import { defineComponent } from "@nuxtjs/composition-api";
import CListItemContent from "@/components/ui-parts/CListItemContent.vue";

/**
 * サイドナビ ユーザーカード
 */
export default defineComponent({
  name: "UserCard",
  components: {
    CListItemContent
  },
  props: {
    bankName: {
      type: String,
      required: true
    },
    babbName: {
      type: String,
      required: true
    },
    userName: {
      type: String,
      required: true
    }
  }
});
