
import { defineComponent, onMounted, ref, useContext } from "@nuxtjs/composition-api";
import SideNavi from "../components/common/admin/sidenavi/SideNavi.vue";
import { baPaymentStore, baStore } from "@/store";
import { provideStore, getStore } from "@/compositions/store";

import "@/assets/css/common.css";

/**
 * 金融機関管理 レイアウト
 */
export default defineComponent({
  name: "AdminLayout",
  components: {
    SideNavi
  },
  middleware: ["check-usage-bapayment-admin"],
  setup() {
    const context = useContext();

    provideStore();
    const { onResize, isMobile, mobileWidth } = getStore();

    const drawer = ref<boolean>(false);
    const isShownSubMenu = ref<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isErrorPage = (): boolean => context._errored;

    onMounted(() => {
      onResize();

      // pcの場合は左メニューを最初から表示する
      if (!isMobile.value) {
        drawer.value = true;
      }

      if (isErrorPage()) {
        isMobile.value = true;
      }
    });

    return {
      baPaymentStore,
      baStore,
      drawer,
      isErrorPage,
      isMobile,
      isShownSubMenu,
      mobileWidth,
      onResize
    };
  },
  head() {
    return {
      title: ""
    };
  }
});
