const middleware = {}

middleware['auth-general'] = require('../middleware/auth-general.ts')
middleware['auth-general'] = middleware['auth-general'].default || middleware['auth-general']

middleware['check-usage-bapayment-admin'] = require('../middleware/check-usage-bapayment-admin.ts')
middleware['check-usage-bapayment-admin'] = middleware['check-usage-bapayment-admin'].default || middleware['check-usage-bapayment-admin']

middleware['check-usage-bapayment'] = require('../middleware/check-usage-bapayment.js')
middleware['check-usage-bapayment'] = middleware['check-usage-bapayment'].default || middleware['check-usage-bapayment']

middleware['receive-pages'] = require('../middleware/receive-pages.ts')
middleware['receive-pages'] = middleware['receive-pages'].default || middleware['receive-pages']

middleware['register-pages'] = require('../middleware/register-pages.ts')
middleware['register-pages'] = middleware['register-pages'].default || middleware['register-pages']

middleware['top-page'] = require('../middleware/top-page.ts')
middleware['top-page'] = middleware['top-page'].default || middleware['top-page']

export default middleware
