import { baStore } from "~/utils/store-accessor";
import { BA_USER_TYPE } from "~/utils/constants";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default function ({ error }) {
  // BAのオーナー以外は404エラー
  if (baStore.user.ba_user_type !== BA_USER_TYPE.COMPANY_OWNER) {
    error({ statusCode: 404 });
  }
}
