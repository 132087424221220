
import { defineComponent, computed } from "@nuxtjs/composition-api";
import { baPaymentStore } from "~/utils/store-accessor";

export default defineComponent({
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  setup() {
    const registered = computed((): boolean => {
      return !!baPaymentStore.user.company_unique_code;
    });
    const contactFormUrl = computed((): string => {
      return registered.value
        ? "https://docs.google.com/forms/d/e/1FAIpQLSfy627YBZEUCcYJJURaPx5j4Hf_7bdC2z8DyIPuRa3ZNWTZcQ/viewform?usp=sf_link"
        : "https://docs.google.com/forms/d/e/1FAIpQLScl37-MFonv8IpXW1G_dOnx03AH1iBjtlGPdqeTC4BR4N3gVA/viewform?usp=sf_link";
    });

    return {
      contactFormUrl
    };
  }
});
