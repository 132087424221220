import { Plugin } from '@nuxt/types';
import { nextTick, ref } from 'vue';

declare module '@nuxt/types' {
    interface Context {
        scrollToError(): void
    }
}

const helperFunctions: Plugin = (context): void => {
  context.scrollToError = (): void => {
    nextTick(() => {
      const errorElement = document.querySelector('.v-input.error--text') as HTMLElement | null;
      const header = document.querySelector('header') as HTMLElement | null;

      if (errorElement) {
        const headerHeight = ref(0);

        if (header && window.innerWidth >= 600) {
          headerHeight.value = header.offsetHeight;
        }
        window.scroll(0, errorElement.offsetTop - headerHeight.value - 10);
      }
    });
  };
};

export default helperFunctions;
