import { Store } from "vuex";
import { getModule } from "vuex-module-decorators";
import { ZBA_BANK_CODE } from "./constants";
import Ba from "@/store/ba";
import BaPayment from "@/store/ba-payment";
import UseLoading from "~/store/use-loading";

// eslint-disable-next-line import/no-mutable-exports
let baStore: Ba;
// eslint-disable-next-line import/no-mutable-exports
let baPaymentStore: BaPayment;
// eslint-disable-next-line import/no-mutable-exports
let useLoading: UseLoading;

/**
 * storeの初期化
 *
 * @param {Store<any>} store ストア
 * @see {@link https://typescript.nuxtjs.org/ja/cookbook/store/} vuex-module-decorators
 */
function initialiseStores(store: Store<any>): void {
  baStore = getModule(Ba, store);
  baPaymentStore = getModule(BaPayment, store);
  useLoading = getModule(UseLoading, store);
}

export { initialiseStores, baStore, baPaymentStore, useLoading };

export const isSystemAdmin = (): boolean => baStore.user.ba_user_type === "system_admin";
export const isBankAdmin = (): boolean => baStore.user.ba_user_type === "bank_admin";
export const isBranchAdmin = (): boolean => baStore.user.ba_user_type === "branch_admin";
export const isBranchUser = (): boolean => baStore.user.ba_user_type === "branch_user";
export const isCompanyOwner = (): boolean => baStore.user.ba_user_type === "company_owner";
export const isCompanyUser = (): boolean => baStore.user.ba_user_type === "company_user";

export const isSystemAdminZba = (): boolean => isSystemAdmin() && baStore.user.ba_user_bank_code === ZBA_BANK_CODE;
export const isSystemAdminOrBankAdmin = (): boolean => isSystemAdmin() || isBankAdmin();
export const isBranchAdminOrBranchUser = (): boolean => isBranchAdmin() || isBranchUser();
