import { Plugin } from '@nuxt/types';
import { ref } from 'vue';
import moment from 'moment';

declare module '@nuxt/types' {
    interface Context {
        currency(value: any): void
        dateFormat(value: any): void
    }
}

const filterFunctions: Plugin = (context) => {
  context.currency = (value: any): string => {
    const parsedNumber = ref(parseInt(value));

    if (typeof parsedNumber.value !== 'number') {
      return '';
    }
    if (isNaN(parsedNumber.value)) {
      return '';
    }
    return (new Intl.NumberFormat('en-US')).format(value);
  };
  context.dateFormat = (date: any): string => {
    if (date === null || date === undefined || date === 0) {
      return '';
    }
    return moment(date).isValid() ? moment(date).format('YYYY/MM/DD') : '';
  };
};
export default filterFunctions;
