export const PlanPricing = () => import('../../components/PlanPricing.vue' /* webpackChunkName: "components/plan-pricing" */).then(c => wrapFunctional(c.default || c))
export const AccountsAccountLogo = () => import('../../components/accounts/AccountLogo.vue' /* webpackChunkName: "components/accounts-account-logo" */).then(c => wrapFunctional(c.default || c))
export const AccountsAccountTable = () => import('../../components/accounts/AccountTable.vue' /* webpackChunkName: "components/accounts-account-table" */).then(c => wrapFunctional(c.default || c))
export const AccountsAgreement = () => import('../../components/accounts/Agreement.vue' /* webpackChunkName: "components/accounts-agreement" */).then(c => wrapFunctional(c.default || c))
export const AccountsCampaignDetailedNotice = () => import('../../components/accounts/CampaignDetailedNotice.vue' /* webpackChunkName: "components/accounts-campaign-detailed-notice" */).then(c => wrapFunctional(c.default || c))
export const AccountsCampaignNotice = () => import('../../components/accounts/CampaignNotice.vue' /* webpackChunkName: "components/accounts-campaign-notice" */).then(c => wrapFunctional(c.default || c))
export const AccountsCampaignWarning = () => import('../../components/accounts/CampaignWarning.vue' /* webpackChunkName: "components/accounts-campaign-warning" */).then(c => wrapFunctional(c.default || c))
export const AccountsCancellationNotice = () => import('../../components/accounts/CancellationNotice.vue' /* webpackChunkName: "components/accounts-cancellation-notice" */).then(c => wrapFunctional(c.default || c))
export const AccountsErrl = () => import('../../components/accounts/Errl.vue' /* webpackChunkName: "components/accounts-errl" */).then(c => wrapFunctional(c.default || c))
export const AccountsLPBanner = () => import('../../components/accounts/LPBanner.vue' /* webpackChunkName: "components/accounts-l-p-banner" */).then(c => wrapFunctional(c.default || c))
export const AccountsPaymentNotice = () => import('../../components/accounts/PaymentNotice.vue' /* webpackChunkName: "components/accounts-payment-notice" */).then(c => wrapFunctional(c.default || c))
export const AccountsPlanChange = () => import('../../components/accounts/PlanChange.vue' /* webpackChunkName: "components/accounts-plan-change" */).then(c => wrapFunctional(c.default || c))
export const AccountsPlanChangeNotice = () => import('../../components/accounts/PlanChangeNotice.vue' /* webpackChunkName: "components/accounts-plan-change-notice" */).then(c => wrapFunctional(c.default || c))
export const AccountsPlanSelection = () => import('../../components/accounts/PlanSelection.vue' /* webpackChunkName: "components/accounts-plan-selection" */).then(c => wrapFunctional(c.default || c))
export const AccountsServiceIntroduction = () => import('../../components/accounts/ServiceIntroduction.vue' /* webpackChunkName: "components/accounts-service-introduction" */).then(c => wrapFunctional(c.default || c))
export const AccountsTermsAndPrivacy = () => import('../../components/accounts/TermsAndPrivacy.vue' /* webpackChunkName: "components/accounts-terms-and-privacy" */).then(c => wrapFunctional(c.default || c))
export const CommonBankAccountFormDialog = () => import('../../components/common/BankAccountFormDialog.vue' /* webpackChunkName: "components/common-bank-account-form-dialog" */).then(c => wrapFunctional(c.default || c))
export const CommonAlert = () => import('../../components/common/CommonAlert.vue' /* webpackChunkName: "components/common-alert" */).then(c => wrapFunctional(c.default || c))
export const CommonHeader = () => import('../../components/common/CommonHeader.vue' /* webpackChunkName: "components/common-header" */).then(c => wrapFunctional(c.default || c))
export const CommonPageTitle = () => import('../../components/common/CommonPageTitle.vue' /* webpackChunkName: "components/common-page-title" */).then(c => wrapFunctional(c.default || c))
export const CommonCustomError = () => import('../../components/common/CustomError.vue' /* webpackChunkName: "components/common-custom-error" */).then(c => wrapFunctional(c.default || c))
export const CommonCustomPagination = () => import('../../components/common/CustomPagination.vue' /* webpackChunkName: "components/common-custom-pagination" */).then(c => wrapFunctional(c.default || c))
export const CommonDeleteDialog = () => import('../../components/common/DeleteDialog.vue' /* webpackChunkName: "components/common-delete-dialog" */).then(c => wrapFunctional(c.default || c))
export const CommonGlobalNotice = () => import('../../components/common/GlobalNotice.vue' /* webpackChunkName: "components/common-global-notice" */).then(c => wrapFunctional(c.default || c))
export const CommonLoadingBar = () => import('../../components/common/LoadingBar.vue' /* webpackChunkName: "components/common-loading-bar" */).then(c => wrapFunctional(c.default || c))
export const CommonMaintenance = () => import('../../components/common/Maintenance.vue' /* webpackChunkName: "components/common-maintenance" */).then(c => wrapFunctional(c.default || c))
export const InvoicesCreateEditForm = () => import('../../components/invoices/CreateEditForm.vue' /* webpackChunkName: "components/invoices-create-edit-form" */).then(c => wrapFunctional(c.default || c))
export const InvoicesCustomCombobox = () => import('../../components/invoices/CustomCombobox.vue' /* webpackChunkName: "components/invoices-custom-combobox" */).then(c => wrapFunctional(c.default || c))
export const InvoicesCustomerInput = () => import('../../components/invoices/CustomerInput.vue' /* webpackChunkName: "components/invoices-customer-input" */).then(c => wrapFunctional(c.default || c))
export const InvoicesDetailInfo = () => import('../../components/invoices/DetailInfo.vue' /* webpackChunkName: "components/invoices-detail-info" */).then(c => wrapFunctional(c.default || c))
export const InvoicesListInfo = () => import('../../components/invoices/ListInfo.vue' /* webpackChunkName: "components/invoices-list-info" */).then(c => wrapFunctional(c.default || c))
export const InvoicesMailInfo = () => import('../../components/invoices/MailInfo.vue' /* webpackChunkName: "components/invoices-mail-info" */).then(c => wrapFunctional(c.default || c))
export const InvoicesMailStatusBadge = () => import('../../components/invoices/MailStatusBadge.vue' /* webpackChunkName: "components/invoices-mail-status-badge" */).then(c => wrapFunctional(c.default || c))
export const InvoicesPriceInput = () => import('../../components/invoices/PriceInput.vue' /* webpackChunkName: "components/invoices-price-input" */).then(c => wrapFunctional(c.default || c))
export const InvoicesRemarksAndMemoInput = () => import('../../components/invoices/RemarksAndMemoInput.vue' /* webpackChunkName: "components/invoices-remarks-and-memo-input" */).then(c => wrapFunctional(c.default || c))
export const UiPartsCBtn = () => import('../../components/ui-parts/CBtn.vue' /* webpackChunkName: "components/ui-parts-c-btn" */).then(c => wrapFunctional(c.default || c))
export const UiPartsCCombobox = () => import('../../components/ui-parts/CCombobox.vue' /* webpackChunkName: "components/ui-parts-c-combobox" */).then(c => wrapFunctional(c.default || c))
export const UiPartsCFlex = () => import('../../components/ui-parts/CFlex.vue' /* webpackChunkName: "components/ui-parts-c-flex" */).then(c => wrapFunctional(c.default || c))
export const UiPartsCListItemContent = () => import('../../components/ui-parts/CListItemContent.vue' /* webpackChunkName: "components/ui-parts-c-list-item-content" */).then(c => wrapFunctional(c.default || c))
export const UiPartsCMenu = () => import('../../components/ui-parts/CMenu.vue' /* webpackChunkName: "components/ui-parts-c-menu" */).then(c => wrapFunctional(c.default || c))
export const UiPartsCRadioGroup = () => import('../../components/ui-parts/CRadioGroup.vue' /* webpackChunkName: "components/ui-parts-c-radio-group" */).then(c => wrapFunctional(c.default || c))
export const UiPartsCSelect = () => import('../../components/ui-parts/CSelect.vue' /* webpackChunkName: "components/ui-parts-c-select" */).then(c => wrapFunctional(c.default || c))
export const UiPartsCTextField = () => import('../../components/ui-parts/CTextField.vue' /* webpackChunkName: "components/ui-parts-c-text-field" */).then(c => wrapFunctional(c.default || c))
export const AdminCommonDialogWrapper = () => import('../../components/admin/common/DialogWrapper.vue' /* webpackChunkName: "components/admin-common-dialog-wrapper" */).then(c => wrapFunctional(c.default || c))
export const CommonFormsCaptionText = () => import('../../components/common/forms/CaptionText.vue' /* webpackChunkName: "components/common-forms-caption-text" */).then(c => wrapFunctional(c.default || c))
export const CommonFormsDatePicker = () => import('../../components/common/forms/DatePicker.vue' /* webpackChunkName: "components/common-forms-date-picker" */).then(c => wrapFunctional(c.default || c))
export const CommonFormsDateTimePicker = () => import('../../components/common/forms/DateTimePicker.vue' /* webpackChunkName: "components/common-forms-date-time-picker" */).then(c => wrapFunctional(c.default || c))
export const CommonFormsFreeFormDatePicker = () => import('../../components/common/forms/FreeFormDatePicker.vue' /* webpackChunkName: "components/common-forms-free-form-date-picker" */).then(c => wrapFunctional(c.default || c))
export const CommonFormsHeading = () => import('../../components/common/forms/Heading.vue' /* webpackChunkName: "components/common-forms-heading" */).then(c => wrapFunctional(c.default || c))
export const CommonFormsImageView = () => import('../../components/common/forms/ImageView.vue' /* webpackChunkName: "components/common-forms-image-view" */).then(c => wrapFunctional(c.default || c))
export const CommonFormsLabelTextField = () => import('../../components/common/forms/LabelTextField.vue' /* webpackChunkName: "components/common-forms-label-text-field" */).then(c => wrapFunctional(c.default || c))
export const CommonFormsNormalLink = () => import('../../components/common/forms/NormalLink.vue' /* webpackChunkName: "components/common-forms-normal-link" */).then(c => wrapFunctional(c.default || c))
export const CommonFormsTitleText = () => import('../../components/common/forms/TitleText.vue' /* webpackChunkName: "components/common-forms-title-text" */).then(c => wrapFunctional(c.default || c))
export const CommonFormsVTitleText = () => import('../../components/common/forms/VTitleText.vue' /* webpackChunkName: "components/common-forms-v-title-text" */).then(c => wrapFunctional(c.default || c))
export const GeneralOfficeProcessRuleTemplatesApp = () => import('../../components/general/office-process-rule-templates/App.vue' /* webpackChunkName: "components/general-office-process-rule-templates-app" */).then(c => wrapFunctional(c.default || c))
export const GeneralOfficeProcessRuleTemplatesCreateTool = () => import('../../components/general/office-process-rule-templates/CreateTool.vue' /* webpackChunkName: "components/general-office-process-rule-templates-create-tool" */).then(c => wrapFunctional(c.default || c))
export const GeneralOfficeProcessRuleTemplatesEditDialog = () => import('../../components/general/office-process-rule-templates/EditDialog.vue' /* webpackChunkName: "components/general-office-process-rule-templates-edit-dialog" */).then(c => wrapFunctional(c.default || c))
export const GeneralOfficeProcessRuleTemplatesMidashi = () => import('../../components/general/office-process-rule-templates/Midashi.vue' /* webpackChunkName: "components/general-office-process-rule-templates-midashi" */).then(c => wrapFunctional(c.default || c))
export const GeneralOfficeProcessRuleTemplatesWordDownload = () => import('../../components/general/office-process-rule-templates/WordDownload.vue' /* webpackChunkName: "components/general-office-process-rule-templates-word-download" */).then(c => wrapFunctional(c.default || c))
export const GeneralTopApp = () => import('../../components/general/top/App.vue' /* webpackChunkName: "components/general-top-app" */).then(c => wrapFunctional(c.default || c))
export const GeneralTopChecklist = () => import('../../components/general/top/Checklist.vue' /* webpackChunkName: "components/general-top-checklist" */).then(c => wrapFunctional(c.default || c))
export const GeneralTopNotice = () => import('../../components/general/top/Notice.vue' /* webpackChunkName: "components/general-top-notice" */).then(c => wrapFunctional(c.default || c))
export const GeneralTopNoticeDenchoKitei = () => import('../../components/general/top/NoticeDenchoKitei.vue' /* webpackChunkName: "components/general-top-notice-dencho-kitei" */).then(c => wrapFunctional(c.default || c))
export const GeneralTopSummaryTop = () => import('../../components/general/top/SummaryTop.vue' /* webpackChunkName: "components/general-top-summary-top" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonHeader = () => import('../../components/receive/common/Header.vue' /* webpackChunkName: "components/receive-common-header" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonImageViewer = () => import('../../components/receive/common/ImageViewer.vue' /* webpackChunkName: "components/receive-common-image-viewer" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonOverlayViewer = () => import('../../components/receive/common/OverlayViewer.vue' /* webpackChunkName: "components/receive-common-overlay-viewer" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonPdfViewer = () => import('../../components/receive/common/PdfViewer.vue' /* webpackChunkName: "components/receive-common-pdf-viewer" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailAiocr = () => import('../../components/admin/bank-setting/detail/Aiocr.vue' /* webpackChunkName: "components/admin-bank-setting-detail-aiocr" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailApp = () => import('../../components/admin/bank-setting/detail/App.vue' /* webpackChunkName: "components/admin-bank-setting-detail-app" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailButtons = () => import('../../components/admin/bank-setting/detail/Buttons.vue' /* webpackChunkName: "components/admin-bank-setting-detail-buttons" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailCampaign = () => import('../../components/admin/bank-setting/detail/Campaign.vue' /* webpackChunkName: "components/admin-bank-setting-detail-campaign" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailCampaignDeleteDialog = () => import('../../components/admin/bank-setting/detail/CampaignDeleteDialog.vue' /* webpackChunkName: "components/admin-bank-setting-detail-campaign-delete-dialog" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailCampaignFormDialog = () => import('../../components/admin/bank-setting/detail/CampaignFormDialog.vue' /* webpackChunkName: "components/admin-bank-setting-detail-campaign-form-dialog" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailCookiePolicy = () => import('../../components/admin/bank-setting/detail/CookiePolicy.vue' /* webpackChunkName: "components/admin-bank-setting-detail-cookie-policy" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailHeader = () => import('../../components/admin/bank-setting/detail/Header.vue' /* webpackChunkName: "components/admin-bank-setting-detail-header" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailInfo = () => import('../../components/admin/bank-setting/detail/Info.vue' /* webpackChunkName: "components/admin-bank-setting-detail-info" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailInfoList = () => import('../../components/admin/bank-setting/detail/InfoList.vue' /* webpackChunkName: "components/admin-bank-setting-detail-info-list" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailInfoListItem = () => import('../../components/admin/bank-setting/detail/InfoListItem.vue' /* webpackChunkName: "components/admin-bank-setting-detail-info-list-item" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailPlanList = () => import('../../components/admin/bank-setting/detail/PlanList.vue' /* webpackChunkName: "components/admin-bank-setting-detail-plan-list" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailPlanListItem = () => import('../../components/admin/bank-setting/detail/PlanListItem.vue' /* webpackChunkName: "components/admin-bank-setting-detail-plan-list-item" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailSaveConfirmDialog = () => import('../../components/admin/bank-setting/detail/SaveConfirmDialog.vue' /* webpackChunkName: "components/admin-bank-setting-detail-save-confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailSaveFinishedDialog = () => import('../../components/admin/bank-setting/detail/SaveFinishedDialog.vue' /* webpackChunkName: "components/admin-bank-setting-detail-save-finished-dialog" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailTerms = () => import('../../components/admin/bank-setting/detail/Terms.vue' /* webpackChunkName: "components/admin-bank-setting-detail-terms" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailTrial = () => import('../../components/admin/bank-setting/detail/Trial.vue' /* webpackChunkName: "components/admin-bank-setting-detail-trial" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailTrialDialog = () => import('../../components/admin/bank-setting/detail/TrialDialog.vue' /* webpackChunkName: "components/admin-bank-setting-detail-trial-dialog" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailTrialErrorDialog = () => import('../../components/admin/bank-setting/detail/TrialErrorDialog.vue' /* webpackChunkName: "components/admin-bank-setting-detail-trial-error-dialog" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingListApp = () => import('../../components/admin/bank-setting/list/App.vue' /* webpackChunkName: "components/admin-bank-setting-list-app" */).then(c => wrapFunctional(c.default || c))
export const AdminBankList = () => import('../../components/admin/bank-setting/list/BankList.vue' /* webpackChunkName: "components/admin-bank-list" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingListHeader = () => import('../../components/admin/bank-setting/list/Header.vue' /* webpackChunkName: "components/admin-bank-setting-list-header" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingListSearch = () => import('../../components/admin/bank-setting/list/Search.vue' /* webpackChunkName: "components/admin-bank-setting-list-search" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesDetailApp = () => import('../../components/admin/companies/detail/App.vue' /* webpackChunkName: "components/admin-companies-detail-app" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesDetailBasicInfo = () => import('../../components/admin/companies/detail/BasicInfo.vue' /* webpackChunkName: "components/admin-companies-detail-basic-info" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesDetailBasicInfoItem = () => import('../../components/admin/companies/detail/BasicInfoItem.vue' /* webpackChunkName: "components/admin-companies-detail-basic-info-item" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesDetailIssueReportTable = () => import('../../components/admin/companies/detail/IssueReportTable.vue' /* webpackChunkName: "components/admin-companies-detail-issue-report-table" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesDetailReceiveReportTable = () => import('../../components/admin/companies/detail/ReceiveReportTable.vue' /* webpackChunkName: "components/admin-companies-detail-receive-report-table" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesDetailReport = () => import('../../components/admin/companies/detail/Report.vue' /* webpackChunkName: "components/admin-companies-detail-report" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesDetailReportSearchForm = () => import('../../components/admin/companies/detail/ReportSearchForm.vue' /* webpackChunkName: "components/admin-companies-detail-report-search-form" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesDetailUnRegister = () => import('../../components/admin/companies/detail/UnRegister.vue' /* webpackChunkName: "components/admin-companies-detail-un-register" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesDetailUnRegisterCancel = () => import('../../components/admin/companies/detail/UnRegisterCancel.vue' /* webpackChunkName: "components/admin-companies-detail-un-register-cancel" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesDetailUnregisterSetting = () => import('../../components/admin/companies/detail/UnregisterSetting.vue' /* webpackChunkName: "components/admin-companies-detail-unregister-setting" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesDetailUseCompanyDetailStore = () => import('../../components/admin/companies/detail/useCompanyDetailStore.ts' /* webpackChunkName: "components/admin-companies-detail-use-company-detail-store" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesListApp = () => import('../../components/admin/companies/list/App.vue' /* webpackChunkName: "components/admin-companies-list-app" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesListHeader = () => import('../../components/admin/companies/list/Header.vue' /* webpackChunkName: "components/admin-companies-list-header" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesListPagination = () => import('../../components/admin/companies/list/Pagination.vue' /* webpackChunkName: "components/admin-companies-list-pagination" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesListPaymentUsageList = () => import('../../components/admin/companies/list/PaymentUsageList.vue' /* webpackChunkName: "components/admin-companies-list-payment-usage-list" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesListSearch = () => import('../../components/admin/companies/list/Search.vue' /* webpackChunkName: "components/admin-companies-list-search" */).then(c => wrapFunctional(c.default || c))
export const AdminCompaniesListUseCompanyStore = () => import('../../components/admin/companies/list/useCompanyStore.ts' /* webpackChunkName: "components/admin-companies-list-use-company-store" */).then(c => wrapFunctional(c.default || c))
export const CommonAccountsCustomersCustomerInput = () => import('../../components/common/accounts/customers/CustomerInput.vue' /* webpackChunkName: "components/common-accounts-customers-customer-input" */).then(c => wrapFunctional(c.default || c))
export const CommonAdminSidenaviNaviMenu = () => import('../../components/common/admin/sidenavi/NaviMenu.vue' /* webpackChunkName: "components/common-admin-sidenavi-navi-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonAdminSidenaviSideNavi = () => import('../../components/common/admin/sidenavi/SideNavi.vue' /* webpackChunkName: "components/common-admin-sidenavi-side-navi" */).then(c => wrapFunctional(c.default || c))
export const CommonAdminSidenaviUserCard = () => import('../../components/common/admin/sidenavi/UserCard.vue' /* webpackChunkName: "components/common-admin-sidenavi-user-card" */).then(c => wrapFunctional(c.default || c))
export const GeneralCommonCustomersCsvUploadDialog = () => import('../../components/general/common/customers/CsvUploadDialog.vue' /* webpackChunkName: "components/general-common-customers-csv-upload-dialog" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonHeader = () => import('../../components/general/issue/common/Header.vue' /* webpackChunkName: "components/general-issue-common-header" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonPdfViewer = () => import('../../components/general/issue/common/PdfViewer.vue' /* webpackChunkName: "components/general-issue-common-pdf-viewer" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueItemsApp = () => import('../../components/general/issue/items/App.vue' /* webpackChunkName: "components/general-issue-items-app" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueItemsCsvDialog = () => import('../../components/general/issue/items/CsvDialog.vue' /* webpackChunkName: "components/general-issue-items-csv-dialog" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueItemsCsvUploadDialog = () => import('../../components/general/issue/items/CsvUploadDialog.vue' /* webpackChunkName: "components/general-issue-items-csv-upload-dialog" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueItemsDeleteItemDialog = () => import('../../components/general/issue/items/DeleteItemDialog.vue' /* webpackChunkName: "components/general-issue-items-delete-item-dialog" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueItemsEditItemDialog = () => import('../../components/general/issue/items/EditItemDialog.vue' /* webpackChunkName: "components/general-issue-items-edit-item-dialog" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueItemsItemLIst = () => import('../../components/general/issue/items/ItemLIst.vue' /* webpackChunkName: "components/general-issue-items-item-l-ist" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueItemsPagination = () => import('../../components/general/issue/items/Pagination.vue' /* webpackChunkName: "components/general-issue-items-pagination" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueItemsSearch = () => import('../../components/general/issue/items/Search.vue' /* webpackChunkName: "components/general-issue-items-search" */).then(c => wrapFunctional(c.default || c))
export const GeneralReportsListApp = () => import('../../components/general/reports/list/App.vue' /* webpackChunkName: "components/general-reports-list-app" */).then(c => wrapFunctional(c.default || c))
export const GeneralReportsListGraph = () => import('../../components/general/reports/list/Graph.vue' /* webpackChunkName: "components/general-reports-list-graph" */).then(c => wrapFunctional(c.default || c))
export const GeneralReportsListSearch = () => import('../../components/general/reports/list/Search.vue' /* webpackChunkName: "components/general-reports-list-search" */).then(c => wrapFunctional(c.default || c))
export const GeneralSettingsDocumentsApp = () => import('../../components/general/settings/documents/App.vue' /* webpackChunkName: "components/general-settings-documents-app" */).then(c => wrapFunctional(c.default || c))
export const GeneralSettingsDocumentsEditRemarksDialog = () => import('../../components/general/settings/documents/EditRemarksDialog.vue' /* webpackChunkName: "components/general-settings-documents-edit-remarks-dialog" */).then(c => wrapFunctional(c.default || c))
export const GeneralSettingsDocumentsRemarks = () => import('../../components/general/settings/documents/Remarks.vue' /* webpackChunkName: "components/general-settings-documents-remarks" */).then(c => wrapFunctional(c.default || c))
export const GeneralSupportFaqApp = () => import('../../components/general/support/faq/App.vue' /* webpackChunkName: "components/general-support-faq-app" */).then(c => wrapFunctional(c.default || c))
export const GeneralSupportFaqBottom = () => import('../../components/general/support/faq/Bottom.vue' /* webpackChunkName: "components/general-support-faq-bottom" */).then(c => wrapFunctional(c.default || c))
export const GeneralSupportFaqDenchoKitei = () => import('../../components/general/support/faq/DenchoKitei.vue' /* webpackChunkName: "components/general-support-faq-dencho-kitei" */).then(c => wrapFunctional(c.default || c))
export const GeneralSupportFaqInquiry = () => import('../../components/general/support/faq/Inquiry.vue' /* webpackChunkName: "components/general-support-faq-inquiry" */).then(c => wrapFunctional(c.default || c))
export const GeneralSupportFaqManual = () => import('../../components/general/support/faq/Manual.vue' /* webpackChunkName: "components/general-support-faq-manual" */).then(c => wrapFunctional(c.default || c))
export const GeneralSupportFaqSubset = () => import('../../components/general/support/faq/Subset.vue' /* webpackChunkName: "components/general-support-faq-subset" */).then(c => wrapFunctional(c.default || c))
export const GeneralSupportFaqSubsetMenu = () => import('../../components/general/support/faq/SubsetMenu.vue' /* webpackChunkName: "components/general-support-faq-subset-menu" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonDetailActionBar = () => import('../../components/receive/common/detail/ActionBar.vue' /* webpackChunkName: "components/receive-common-detail-action-bar" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonDetailDeleteModal = () => import('../../components/receive/common/detail/DeleteModal.vue' /* webpackChunkName: "components/receive-common-detail-delete-modal" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonDetail = () => import('../../components/receive/common/detail/Detail.vue' /* webpackChunkName: "components/receive-common-detail" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonDetailBankInfo = () => import('../../components/receive/common/detail/DetailBankInfo.vue' /* webpackChunkName: "components/receive-common-detail-bank-info" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonDetailCustomerInfo = () => import('../../components/receive/common/detail/DetailCustomerInfo.vue' /* webpackChunkName: "components/receive-common-detail-customer-info" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonDetailFileInfo = () => import('../../components/receive/common/detail/DetailFileInfo.vue' /* webpackChunkName: "components/receive-common-detail-file-info" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonDetailViewer = () => import('../../components/receive/common/detail/DetailViewer.vue' /* webpackChunkName: "components/receive-common-detail-viewer" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonDetailHistoryModal = () => import('../../components/receive/common/detail/HistoryModal.vue' /* webpackChunkName: "components/receive-common-detail-history-modal" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonDetailToolBar = () => import('../../components/receive/common/detail/ToolBar.vue' /* webpackChunkName: "components/receive-common-detail-tool-bar" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonIndexActionBar = () => import('../../components/receive/common/index/ActionBar.vue' /* webpackChunkName: "components/receive-common-index-action-bar" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonIndexAiocrInfoModal = () => import('../../components/receive/common/index/AiocrInfoModal.vue' /* webpackChunkName: "components/receive-common-index-aiocr-info-modal" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonIndexAiocrTools = () => import('../../components/receive/common/index/AiocrTools.vue' /* webpackChunkName: "components/receive-common-index-aiocr-tools" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonIndexCsvModal = () => import('../../components/receive/common/index/CsvModal.vue' /* webpackChunkName: "components/receive-common-index-csv-modal" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonIndexHeader = () => import('../../components/receive/common/index/Header.vue' /* webpackChunkName: "components/receive-common-index-header" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonIndexPagination = () => import('../../components/receive/common/index/Pagination.vue' /* webpackChunkName: "components/receive-common-index-pagination" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonIndexSearch = () => import('../../components/receive/common/index/Search.vue' /* webpackChunkName: "components/receive-common-index-search" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonIndexTable = () => import('../../components/receive/common/index/Table.vue' /* webpackChunkName: "components/receive-common-index-table" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonInputAiocr = () => import('../../components/receive/common/input/Aiocr.vue' /* webpackChunkName: "components/receive-common-input-aiocr" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonInputAiocrErrorModal = () => import('../../components/receive/common/input/AiocrErrorModal.vue' /* webpackChunkName: "components/receive-common-input-aiocr-error-modal" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonInputAiocrReadingModal = () => import('../../components/receive/common/input/AiocrReadingModal.vue' /* webpackChunkName: "components/receive-common-input-aiocr-reading-modal" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonInputAiocrUpdateFieldIcon = () => import('../../components/receive/common/input/AiocrUpdateFieldIcon.vue' /* webpackChunkName: "components/receive-common-input-aiocr-update-field-icon" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonInputAiocrUploadModal = () => import('../../components/receive/common/input/AiocrUploadModal.vue' /* webpackChunkName: "components/receive-common-input-aiocr-upload-modal" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonInput = () => import('../../components/receive/common/input/Input.vue' /* webpackChunkName: "components/receive-common-input" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonInputBankInfo = () => import('../../components/receive/common/input/InputBankInfo.vue' /* webpackChunkName: "components/receive-common-input-bank-info" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonInputConfirmCustomerModal = () => import('../../components/receive/common/input/InputConfirmCustomerModal.vue' /* webpackChunkName: "components/receive-common-input-confirm-customer-modal" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonInputCustomerInfo = () => import('../../components/receive/common/input/InputCustomerInfo.vue' /* webpackChunkName: "components/receive-common-input-customer-info" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonInputFile = () => import('../../components/receive/common/input/InputFile.vue' /* webpackChunkName: "components/receive-common-input-file" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonInputFileInfo = () => import('../../components/receive/common/input/InputFileInfo.vue' /* webpackChunkName: "components/receive-common-input-file-info" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonInputSaveButton = () => import('../../components/receive/common/input/InputSaveButton.vue' /* webpackChunkName: "components/receive-common-input-save-button" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonInputSaveModal = () => import('../../components/receive/common/input/InputSaveModal.vue' /* webpackChunkName: "components/receive-common-input-save-modal" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonInputTipsIcon = () => import('../../components/receive/common/input/InputTipsIcon.vue' /* webpackChunkName: "components/receive-common-input-tips-icon" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonInputViewer = () => import('../../components/receive/common/input/InputViewer.vue' /* webpackChunkName: "components/receive-common-input-viewer" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonSnapshotHeader = () => import('../../components/receive/common/snapshot/Header.vue' /* webpackChunkName: "components/receive-common-snapshot-header" */).then(c => wrapFunctional(c.default || c))
export const ReceiveCommonSnapshotToolBar = () => import('../../components/receive/common/snapshot/ToolBar.vue' /* webpackChunkName: "components/receive-common-snapshot-tool-bar" */).then(c => wrapFunctional(c.default || c))
export const ReceiveInvoicesCreateApp = () => import('../../components/receive/invoices/create/App.vue' /* webpackChunkName: "components/receive-invoices-create-app" */).then(c => wrapFunctional(c.default || c))
export const ReceiveInvoicesDetailApp = () => import('../../components/receive/invoices/detail/App.vue' /* webpackChunkName: "components/receive-invoices-detail-app" */).then(c => wrapFunctional(c.default || c))
export const ReceiveInvoicesEditApp = () => import('../../components/receive/invoices/edit/App.vue' /* webpackChunkName: "components/receive-invoices-edit-app" */).then(c => wrapFunctional(c.default || c))
export const ReceiveInvoicesIndexApp = () => import('../../components/receive/invoices/index/App.vue' /* webpackChunkName: "components/receive-invoices-index-app" */).then(c => wrapFunctional(c.default || c))
export const ReceiveInvoicesIndexSearchItem = () => import('../../components/receive/invoices/index/SearchItem.vue' /* webpackChunkName: "components/receive-invoices-index-search-item" */).then(c => wrapFunctional(c.default || c))
export const ReceiveInvoicesSnapshotApp = () => import('../../components/receive/invoices/snapshot/App.vue' /* webpackChunkName: "components/receive-invoices-snapshot-app" */).then(c => wrapFunctional(c.default || c))
export const ReceiveOthersCreateApp = () => import('../../components/receive/others/create/App.vue' /* webpackChunkName: "components/receive-others-create-app" */).then(c => wrapFunctional(c.default || c))
export const ReceiveOthersDetailApp = () => import('../../components/receive/others/detail/App.vue' /* webpackChunkName: "components/receive-others-detail-app" */).then(c => wrapFunctional(c.default || c))
export const ReceiveOthersEditApp = () => import('../../components/receive/others/edit/App.vue' /* webpackChunkName: "components/receive-others-edit-app" */).then(c => wrapFunctional(c.default || c))
export const ReceiveOthersIndexApp = () => import('../../components/receive/others/index/App.vue' /* webpackChunkName: "components/receive-others-index-app" */).then(c => wrapFunctional(c.default || c))
export const ReceiveOthersIndexSearchItem = () => import('../../components/receive/others/index/SearchItem.vue' /* webpackChunkName: "components/receive-others-index-search-item" */).then(c => wrapFunctional(c.default || c))
export const ReceiveOthersSnapshotApp = () => import('../../components/receive/others/snapshot/App.vue' /* webpackChunkName: "components/receive-others-snapshot-app" */).then(c => wrapFunctional(c.default || c))
export const ReceiveReceiptsCreateApp = () => import('../../components/receive/receipts/create/App.vue' /* webpackChunkName: "components/receive-receipts-create-app" */).then(c => wrapFunctional(c.default || c))
export const ReceiveReceiptsDetailApp = () => import('../../components/receive/receipts/detail/App.vue' /* webpackChunkName: "components/receive-receipts-detail-app" */).then(c => wrapFunctional(c.default || c))
export const ReceiveReceiptsEditApp = () => import('../../components/receive/receipts/edit/App.vue' /* webpackChunkName: "components/receive-receipts-edit-app" */).then(c => wrapFunctional(c.default || c))
export const ReceiveReceiptsIndexApp = () => import('../../components/receive/receipts/index/App.vue' /* webpackChunkName: "components/receive-receipts-index-app" */).then(c => wrapFunctional(c.default || c))
export const ReceiveReceiptsIndexSearchItem = () => import('../../components/receive/receipts/index/SearchItem.vue' /* webpackChunkName: "components/receive-receipts-index-search-item" */).then(c => wrapFunctional(c.default || c))
export const ReceiveReceiptsSnapshotApp = () => import('../../components/receive/receipts/snapshot/App.vue' /* webpackChunkName: "components/receive-receipts-snapshot-app" */).then(c => wrapFunctional(c.default || c))
export const CommonAccountsCustomersCreateApp = () => import('../../components/common/accounts/customers/create/App.vue' /* webpackChunkName: "components/common-accounts-customers-create-app" */).then(c => wrapFunctional(c.default || c))
export const CommonAccountsCustomersEditApp = () => import('../../components/common/accounts/customers/edit/App.vue' /* webpackChunkName: "components/common-accounts-customers-edit-app" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonDetailActionBar = () => import('../../components/general/issue/common/detail/ActionBar.vue' /* webpackChunkName: "components/general-issue-common-detail-action-bar" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonDetailButtonBar = () => import('../../components/general/issue/common/detail/ButtonBar.vue' /* webpackChunkName: "components/general-issue-common-detail-button-bar" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonDetailDeleteModal = () => import('../../components/general/issue/common/detail/DeleteModal.vue' /* webpackChunkName: "components/general-issue-common-detail-delete-modal" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonDetail = () => import('../../components/general/issue/common/detail/Detail.vue' /* webpackChunkName: "components/general-issue-common-detail" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonDetailTailInfo = () => import('../../components/general/issue/common/detail/DetailTailInfo.vue' /* webpackChunkName: "components/general-issue-common-detail-tail-info" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonDetailHeader = () => import('../../components/general/issue/common/detail/Header.vue' /* webpackChunkName: "components/general-issue-common-detail-header" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonDetailHistoryModal = () => import('../../components/general/issue/common/detail/HistoryModal.vue' /* webpackChunkName: "components/general-issue-common-detail-history-modal" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonDetailToolBar = () => import('../../components/general/issue/common/detail/ToolBar.vue' /* webpackChunkName: "components/general-issue-common-detail-tool-bar" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonIndexActionBar = () => import('../../components/general/issue/common/index/ActionBar.vue' /* webpackChunkName: "components/general-issue-common-index-action-bar" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonIndexCsvModal = () => import('../../components/general/issue/common/index/CsvModal.vue' /* webpackChunkName: "components/general-issue-common-index-csv-modal" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonIndexHeader = () => import('../../components/general/issue/common/index/Header.vue' /* webpackChunkName: "components/general-issue-common-index-header" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonIndexPagination = () => import('../../components/general/issue/common/index/Pagination.vue' /* webpackChunkName: "components/general-issue-common-index-pagination" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonIndexSearch = () => import('../../components/general/issue/common/index/Search.vue' /* webpackChunkName: "components/general-issue-common-index-search" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonIndexSearchItem = () => import('../../components/general/issue/common/index/SearchItem.vue' /* webpackChunkName: "components/general-issue-common-index-search-item" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonIndexTable = () => import('../../components/general/issue/common/index/Table.vue' /* webpackChunkName: "components/general-issue-common-index-table" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonInput = () => import('../../components/general/issue/common/input/Input.vue' /* webpackChunkName: "components/general-issue-common-input" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonInputBasicInfo = () => import('../../components/general/issue/common/input/InputBasicInfo.vue' /* webpackChunkName: "components/general-issue-common-input-basic-info" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonInputCompanyInfo = () => import('../../components/general/issue/common/input/InputCompanyInfo.vue' /* webpackChunkName: "components/general-issue-common-input-company-info" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonInputCreateCustomerModal = () => import('../../components/general/issue/common/input/InputCreateCustomerModal.vue' /* webpackChunkName: "components/general-issue-common-input-create-customer-modal" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonInputCreateItemModal = () => import('../../components/general/issue/common/input/InputCreateItemModal.vue' /* webpackChunkName: "components/general-issue-common-input-create-item-modal" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonInputCustomerInfo = () => import('../../components/general/issue/common/input/InputCustomerInfo.vue' /* webpackChunkName: "components/general-issue-common-input-customer-info" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonInputDeleteItemModal = () => import('../../components/general/issue/common/input/InputDeleteItemModal.vue' /* webpackChunkName: "components/general-issue-common-input-delete-item-modal" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonInputPriceInfo = () => import('../../components/general/issue/common/input/InputPriceInfo.vue' /* webpackChunkName: "components/general-issue-common-input-price-info" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonInputRemarksAndMemoInfo = () => import('../../components/general/issue/common/input/InputRemarksAndMemoInfo.vue' /* webpackChunkName: "components/general-issue-common-input-remarks-and-memo-info" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonInputSaveModal = () => import('../../components/general/issue/common/input/InputSaveModal.vue' /* webpackChunkName: "components/general-issue-common-input-save-modal" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonLabelDeleteLabelDialog = () => import('../../components/general/issue/common/label/DeleteLabelDialog.vue' /* webpackChunkName: "components/general-issue-common-label-delete-label-dialog" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonLabelFormDialog = () => import('../../components/general/issue/common/label/LabelFormDialog.vue' /* webpackChunkName: "components/general-issue-common-label-form-dialog" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonSnapshotHeader = () => import('../../components/general/issue/common/snapshot/Header.vue' /* webpackChunkName: "components/general-issue-common-snapshot-header" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueCommonSnapshotHistoryInfo = () => import('../../components/general/issue/common/snapshot/HistoryInfo.vue' /* webpackChunkName: "components/general-issue-common-snapshot-history-info" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueDelivertySlipsSnapshotApp = () => import('../../components/general/issue/deliverty-slips/snapshot/App.vue' /* webpackChunkName: "components/general-issue-deliverty-slips-snapshot-app" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueEstimatesSnapshotApp = () => import('../../components/general/issue/estimates/snapshot/App.vue' /* webpackChunkName: "components/general-issue-estimates-snapshot-app" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueInvoicesSnapshotApp = () => import('../../components/general/issue/invoices/snapshot/App.vue' /* webpackChunkName: "components/general-issue-invoices-snapshot-app" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueReceiptsCreateApp = () => import('../../components/general/issue/receipts/create/App.vue' /* webpackChunkName: "components/general-issue-receipts-create-app" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueReceiptsDetailApp = () => import('../../components/general/issue/receipts/detail/App.vue' /* webpackChunkName: "components/general-issue-receipts-detail-app" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueReceiptsEditApp = () => import('../../components/general/issue/receipts/edit/App.vue' /* webpackChunkName: "components/general-issue-receipts-edit-app" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueReceiptsIndexApp = () => import('../../components/general/issue/receipts/index/App.vue' /* webpackChunkName: "components/general-issue-receipts-index-app" */).then(c => wrapFunctional(c.default || c))
export const GeneralIssueReceiptsSnapshotApp = () => import('../../components/general/issue/receipts/snapshot/App.vue' /* webpackChunkName: "components/general-issue-receipts-snapshot-app" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailBankCodeFreeTrialReservationApp = () => import('../../components/admin/bank-setting/detail/_bank_code/free-trial-reservation/App.vue' /* webpackChunkName: "components/admin-bank-setting-detail-bank-code-free-trial-reservation-app" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailBankCodeFreeTrialReservationPagination = () => import('../../components/admin/bank-setting/detail/_bank_code/free-trial-reservation/Pagination.vue' /* webpackChunkName: "components/admin-bank-setting-detail-bank-code-free-trial-reservation-pagination" */).then(c => wrapFunctional(c.default || c))
export const AdminBankSettingDetailBankCodeFreeTrialDetail = () => import('../../components/admin/bank-setting/detail/_bank_code/free-trial-reservation/TrialDetail.vue' /* webpackChunkName: "components/admin-bank-setting-detail-bank-code-free-trial-detail" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
