import { Plugin } from "@nuxt/types";
import { ref } from "vue";
import dayjs from "dayjs";

declare module "@nuxt/types" {
  interface Context {
    required(label: string, boolean: boolean): any;
    requiredIf(label: string, baseAttribute?: any, baseValue?: any): any;
    min(num: number): (value: number | null) => boolean | string;
    max(num: number): (value: number | null) => boolean | string;
    minLength(length: number): any;
    maxLength(length: number): any;
    email(): any;
    multipleEmail(): any;
    password(requiredAlpha: boolean): any;
    phone(): any;
    fax(): any;
    postalCode(): any;
    numeric(): any;
    match(sourceLabel: string, sourceValue: any, compareValue: any): any;
    different(sourceLabel: string, compareLabel: string, sourceValue: any, compareValue: any): any;
    registeredNo(): any;
    quantity(): any;
    unit(): any;
    price(): any;
    totalAmount(): (value: string | null) => boolean | string;
    beforeDate(v: any, after: any): boolean | string;
    afterDate(v: any, before: any): boolean | string;
    bankAccountName(): (value: string | null) => boolean | string;
    lt(v: number, target: number): boolean | string;
    gt(v: number, target: number): boolean | string;
  }
}

const validationRules: Plugin = (context) => {
  context.required = (label: string, boolean = false): any => {
    return (value: any) => {
      if (typeof value !== "undefined" && value !== null && value.toString().replace(/\s+/g, "").trim()) {
        return true;
      }
      if ([0, 1].includes(value) && boolean) {
        return true;
      }
      return `${label}は必須です。`;
    };
  };
  context.requiredIf = (label: string, baseAttribute?: any, baseValue?: any): any => {
    return (value: any) => {
      if (baseAttribute == null) {
        return true;
      }
      if (baseValue !== null) {
        if (baseAttribute !== baseValue || value !== null) {
          return true;
        }
      } else if (value !== null && value.toString().replace(/\s+/g, "").trim()) {
        return true;
      }
      return `${label}は必須です。`;
    };
  };
  context.min = (num = 0): ((value: number | null) => boolean | string) => {
    return (value: number | null) => {
      if (value !== null && value < num) {
        return `${num}以上の値を入力してください。`;
      }
      return true;
    };
  };
  context.max = (num = 0): ((value: number | null) => boolean | string) => {
    return (value: number | null) => {
      if (value && value > num) {
        return `${num}以下の値を入力してください。`;
      }
      return true;
    };
  };
  context.minLength = (length = 1): any => {
    return (value: any) => {
      if (value && value.toString().length < length) {
        return `${length}文字以上にしてください。`;
      }
      return true;
    };
  };
  context.maxLength = (length = 100): any => {
    return (value: any) => {
      if (value && value.toString().length > length) {
        return `${length}文字以下にしてください。`;
      }
      return true;
    };
  };
  context.email = (): any => {
    return (value: any) => {
      if (
        value &&
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        )
      ) {
        return "正しいメールアドレスを入力して下さい。";
      }
      return true;
    };
  };
  context.multipleEmail = (): any => {
    return (value: any) => {
      if (value) {
        const addresses: string[] = value.split(",");
        for (const address of addresses) {
          if (
            !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              address
            )
          ) {
            return "正しいメールアドレスを入力して下さい。";
          }
        }
        if (addresses.length) {
          const setElements = new Set(addresses);
          if (setElements.size !== addresses.length) {
            return "重複しているメールアドレスがあります。";
          }
        }
      }
      return true;
    };
  };
  context.password = (requiredAlpha = true): any => {
    return (value: any) => {
      if (value) {
        const specailChars = ref(["@", "#", "$", "%", "&", "?", "!"]);
        const hasSpecialChar = (value: any): boolean => {
          return specailChars.value.some((char) => {
            return value.includes(char);
          });
        };
        const hasUnicode = (value: any): boolean => {
          value = value.replace(/[a-zA-Z0-9]/g, "");
          specailChars.value.forEach((char) => {
            value = value.replaceAll(char, "");
          });
          return value !== "";
        };
        if (requiredAlpha && (!/^.*(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/.test(value) || !hasSpecialChar(value))) {
          return "パスワードは「英大文字」「英小文字」「数字」「記号」を含む必要があります。";
        }
        if (hasUnicode(value)) {
          return "パスワードは半角英数字・記号で入力してください。";
        }
      }
      return true;
    };
  };
  context.phone = (): any => {
    return (value: any) => {
      if (value) {
        const countCharacter = ref(value.replace(/-/g, "").length);

        if (/^(\d{2,5})(-?)(\d{1,4})(-?)(\d{4})+$/.test(value) && countCharacter.value === 10) {
          return true;
        }
        if (/^(\d{3})(-?)(\d{4})(-?)(\d{4})+$/.test(value) && countCharacter.value === 11) {
          return true;
        }
        return "電話形式が無効です。";
      }
      return true;
    };
  };
  context.fax = (): any => {
    return (value: any) => {
      if (value) {
        const countCharacter = ref(value.replace(/-/g, "").length);

        if (/^(\d{2,5})(-?)(\d{1,4})(-?)(\d{4})+$/.test(value) && countCharacter.value === 10) {
          return true;
        }
        if (/^(\d{3})(-?)(\d{4})(-?)(\d{4})+$/.test(value) && countCharacter.value === 11) {
          return true;
        }
        return "FAX形式が無効です。";
      }
      return true;
    };
  };
  context.postalCode = (): any => {
    return (value: any) => {
      return !value || /^(\d{7})+$/.test(value) || "郵便番号形式が無効です。";
    };
  };
  context.numeric = (): any => {
    return (value: any) => {
      if (value) {
        const parsedNumber = ref(parseInt(value));

        if (typeof parsedNumber.value !== "number" || isNaN(parsedNumber.value) || /[^0-9]/.test(value)) {
          return "半角数字を入力してください。";
        }
      }
      return true;
    };
  };
  context.match = (sourceLabel: string, sourceValue: any, compareValue: any): any => {
    return (
      (!sourceValue && !compareValue) ||
      sourceValue === compareValue ||
      `${sourceLabel}と${sourceLabel}（確認）が一致しません。`
    );
  };
  context.different = (sourceLabel: string, compareLabel: string, sourceValue: any, compareValue: any): any => {
    return (
      (!sourceValue && !compareValue) ||
      sourceValue !== compareValue ||
      `${sourceLabel}と${compareLabel}とは異なる必要があります`
    );
  };
  context.registeredNo = (): any => {
    return (value: any) => {
      return !value || /^[0-9a-zA-Z-_]*$/.test(value) || "英数字、「-」、「_」以外の文字は入力不可能です。";
    };
  };
  context.quantity = (): any => {
    return (value: any) => {
      return !value || /^-?[0-9]{1,8}\.[0-9]{1,2}$|^-?[0-9]{1,8}$/.test(value) || "整数8桁小数2桁以内";
    };
  };
  context.unit = (): any => {
    return (value: any) => {
      if (value && value.toString().length > 10) {
        return "10文字以内";
      }
      return true;
    };
  };
  context.price = (): any => {
    return (value: any) => {
      return !value || /^-?[0-9]{1,13}\.[0-9]{1,3}$|^-?[0-9]{1,13}$/.test(value) || "整数13桁小数3桁以内";
    };
  };
  context.totalAmount = (): ((value: string | null) => boolean | string) => {
    return (value: string | null) => {
      return !value || /^-?[0-9]{1,13}$/.test(value) || "13桁以内の数値を入力してください";
    };
  };
  context.beforeDate = (v: any, after: any): boolean | string => {
    if (v && after) {
      if (dayjs(v).isAfter(dayjs(after))) {
        return "終了日 以前の日付を入力してください";
      }
    }
    return true;
  };
  context.afterDate = (v: any, before: any): boolean | string => {
    if (v && before) {
      if (dayjs(v).isBefore(dayjs(before))) {
        return "開始日 以降の日付を入力してください";
      }
    }
    return true;
  };
  context.bankAccountName = (): ((value: any) => boolean | string) => {
    return (value: string | null) => {
      /* eslint-disable no-irregular-whitespace */
      return !value || /^[ァ-ヶー　゛゜（）「」／．－￥]*$/.test(value) || "入力できない文字が含まれています。";
    };
  };
  context.lt = (v: number, target: number): boolean | string => {
    if (v && target) {
      if (Number(v) > Number(target)) {
        return "上限金額未満の数値を入力してください。";
      }
    }
    return true;
  };
  context.gt = (v: number, target: number): boolean | string => {
    if (v && target) {
      if (Number(v) < Number(target)) {
        return "下限金額超過の数値を入力してください。";
      }
    }
    return true;
  };
};

export default validationRules;
