import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { LocalScheme } from '~auth/runtime'
import { Oauth2Scheme } from '~auth/runtime'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "watchLoggedIn": true,
  "redirect": {
    "login": "/login",
    "logout": "/",
    "home": "/top",
    "callback": "/callback"
  },
  "vuex": false,
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/"
    }
  },
  "localStorage": {
    "prefix": "auth."
  },
  "defaultStrategy": "local"
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new LocalScheme($auth, {
  "token": {
    "property": "data.token",
    "global": true,
    "maxAge": 2592000
  },
  "user": {
    "property": "data",
    "autoFetch": false
  },
  "endpoints": {
    "login": {
      "url": "/api/v1/auth/login",
      "method": "post",
      "propertyName": "data.token"
    },
    "user": {
      "url": "/api/v1/auth/me",
      "method": "get",
      "propertyName": "data"
    },
    "logout": {
      "url": "/api/v1/auth/logout",
      "method": "post"
    }
  },
  "name": "local"
}))

  // bamidp
  $auth.registerStrategy('bamidp', new Oauth2Scheme($auth, {
  "endpoints": {
    "authorization": "https://bamanager2-idp.test.kkdv.tech/oidc/ba-payment/auth",
    "token": "https://bamanager2-idp.test.kkdv.tech/oidc/ba-payment/token",
    "logout": "https://bamanager2-idp.test.kkdv.tech/oidc/ba-payment/auth/logout"
  },
  "token": {
    "property": "id_token",
    "type": "Bearer",
    "maxAge": 86400
  },
  "refreshToken": {
    "property": "refresh_token",
    "maxAge": 604800
  },
  "responseType": "code",
  "grantType": "authorization_code",
  "clientId": "ba-payment",
  "scope": [
    "openid"
  ],
  "name": "bamidp"
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
