import Ba from "~/store/ba";
import { baStore, baPaymentStore } from "~/utils/store-accessor";
import { registerAddPlanLink } from "~/utils/routes";
import { isSubscribedReceivePlan } from "~/utils";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default function ({ error, redirect }) {
  /**
   * 受取型の公開設定を確認する
   * @param baStore
   */
  const isPublicReceivePlan = (baStore: Ba) => {
    return baStore.bank.is_enable_payment_receive;
  };

  // 1. 受取型の公開設定を確認する
  if (!isPublicReceivePlan(baStore)) {
    error({
      statusCode: 403,
      customMessage:
        `大変恐れ入りますが、ご契約の${baStore.bank.ba_name}では現在「受取機能」はご利用いただけません。` +
        "\nお客様にはご不便をおかけいたしますが、何卒ご理解とご協力を賜りますようお願い申し上げます。"
    });
    return;
  }

  // 2. 受取型未契約の場合は契約画面へリダイレクトする
  if (!isSubscribedReceivePlan(baPaymentStore)) {
    redirect(registerAddPlanLink);
  }
}
