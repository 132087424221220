/** 管理画面 **/
export const adminCompaniesListLink = "/admin/companies/list";

/** 企業側画面 **/
export const topLink = "/top";

// アカウント関連
export const changePermissionsLink = "/accounts/change/permissions";
export const registerLink = "/accounts/register";
export const registerConfirmLink = "/accounts/register/confirm";
export const registerAddPlanLink = "/accounts/register/addplan";

// 利用規約関連
export const chantoTermLink = "/terms/chanto-term";
export const kokopelliIdTermLink = "/terms/kokopelli-id-term";

// サポート
export const supportFaqLink = "/support/faq";
export const supportQaLink = "/support/qa";

// クッキーポリシー
export const cookiePolicyLink = "/cookie-policy";
